import React from 'react';
import { Row, Col, Card } from 'antd';
import './Stories.css';

const projectData = [
    {
        title: 'Payroll and Attendance Management System, HR tech',
        description: 'For a client in HR-tech, we revamped a payroll and attendance system using Node.js, React, and AWS, improving scalability and security. We rebuilt core systems, implemented VPC and subnet configurations, and enhanced API security. These updates ensured the platform handled high traffic efficiently, resulting in a feature-rich, secure, and scalable solution that boosted user satisfaction and strengthened the company’s market position.',
    },
    {
        title: 'Ad management automation, Food-tech',
        description: 'Worked on multiple projects for a leading food tech platform. Built features like ad management automation, single-click payments, and backend systems for search and nutrition using Node.js, Golang, and AWS. Automated ad handling for brands like HUL and Nestlé, migrated payment systems to asynchronous processing and integrated a scalable search service. These enhancements improved efficiency, scalability, and user satisfaction, driving significant value for the Food-Tech client across multiple business verticals.',
    },
    {
        title: 'CRM and lead nurturing system, Ed-tech',
        description: 'For an Ed-Tech client, we built an in-house CRM and lead nurturing system using Zapier, Calendly, Zoho, Stripe, and WhatsApp. We automated KYC for 50K+ leads monthly with WhatsApp bots, reducing costs by ₹1.5 crore annually and CAC by 40%. The onboarding process was automated, cutting costs by 50% and scaling capacity 3x. These enhancements boosted Lead-to-Customer conversions by 10% and improved operational efficiency by 13%, driving significant value for 30,000+ SMBs.',
    },
];

const Stories = () => {
    return (
        <div className="stories-container">
            <h2 className="section-heading">Stories / Past Projects</h2>
            <p className="section-description">
                Here’s a glimpse of some of our successful past projects that helped our clients reach new heights.
            </p>

            <Row gutter={[16, 16]} justify="center">
                {projectData.map((project, index) => (
                    <Col xs={24} sm={12} md={8} key={index}>
                        <Card className="project-card" hoverable>
                            <h3 className="project-title">{project.title}</h3>
                            <p className="project-description">{project.description}</p>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Stories;
