import React, { useState, useEffect, useRef } from "react";
import "./ScrollHideDiv.css";
import { Menu } from 'antd';
import { Link } from 'react-scroll';
import { getHeaderHeight } from './utils';

const ScrollHideDiv = () => {
    const [showDiv, setShowDiv] = useState(true); // State to manage div visibility
    const [lastScrollY, setLastScrollY] = useState(0); // To store last scroll position

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY && currentScrollY > 100) {
            // If we scrolled down and passed a point (e.g., 100px)
            setShowDiv(false);
        } else {
            // If we scrolled up
            setShowDiv(true);
        }

        setLastScrollY(currentScrollY);
    };

    const headerRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const updateHeaderHeight = () => {
            setHeaderHeight(getHeaderHeight());
        }

        updateHeaderHeight();
        window.addEventListener('resize', updateHeaderHeight);

        return () => {
            window.removeEventListener('resize', updateHeaderHeight);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll); // Cleanup listener on unmount
        };
    }, [lastScrollY]);

    return (
        // <div className={`top-div ${showDiv ? "visible" : "hidden"}`}>
        //     MaroonDev
        // </div>

        <div className={`top-page-content ${showDiv ? "with-top-div" : "without-top-div"}`}>
            {/* Top div that hides and shows based on scroll */}
            <div className={`top-div ${showDiv ? "visible" : "hidden"}`}>
                MaroonDev
            </div>

            {/* The rest of your content here */}
            <div className="top-main-content">
                <Menu theme="dark" mode="horizontal">
                    <Menu.Item key="1">
                        <Link to="landing-page-v3" smooth={true} duration={500} offset={-headerHeight}>About us</Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to="services-v2" smooth={true} duration={500} offset={-headerHeight}>What we do</Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to="tech-stack-v3" smooth={true} duration={500} offset={-headerHeight}>Stack</Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to="why-us-v2" smooth={true} duration={500} offset={-headerHeight}>Why us</Link>
                    </Menu.Item>
                    <Menu.Item key="5">
                        <Link to="how-we-work-v2" smooth={true} duration={500} offset={-headerHeight}>How we work</Link>
                    </Menu.Item>
                    <Menu.Item key="6">
                        <Link to="testimonials-v3" smooth={true} duration={500} offset={-headerHeight}>Testimonials</Link>
                    </Menu.Item>
                </Menu>
            </div>
        </div>

    );
};

export default ScrollHideDiv;
