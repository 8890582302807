import React from 'react';
import './App.css';
import Home from './components/Home';
import ServicesV2 from './components/ServicesV2';
import TechStackV3 from './components/TechStackV3';
import WhyUsV2 from './components/WhyUsV2';
import HowWeWorkV2 from './components/HowWeWorkV2';
import Footer from './components/Footer';
import { Layout } from 'antd';
import TestimonialsV3 from './components/TestimonialsV3';
import LandingPageV3 from './components/LandingPageV3';
import Stories from './components/Stories';

const { Content } = Layout;

function App() {
  return (
    <Layout className="App">
      <Content>
        <Home />
        <LandingPageV3 />
        <ServicesV2 />
        <TechStackV3 />
        <WhyUsV2 />
        <HowWeWorkV2 />
        <Stories />
        <TestimonialsV3 />
      </Content>
      <Footer />
    </Layout>
  );
}

export default App;
