import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./AnimatedSentence.css";

const { Text } = Typography;

const AnimatedSentence = () => {
    const words = ["Reliable", "Secure", "Optimised", "Cost-Effective"];
    const [currentWord, setCurrentWord] = useState(0);

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 2000);

        return () => clearInterval(wordInterval);
    }, [words.length]);

    return (
        <div className="animated-sentence-container">
            <Text className="sentence-text">
                Launch {" "}
                <span className="animated-word">{words[currentWord]}</span><br /> MVP Quickly!
            </Text>
        </div>
    );
};

export default AnimatedSentence;
