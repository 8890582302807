import React from 'react';
import { Space, Button, Row, Col } from 'antd';
import './LandingPageV3.css';
import StyledBulletPoints from './StyledBulletPoints';
import { FORM_LINK } from './../constants';
import AnimatedSentence from './AnimatedSentence';

const LandingPageV3 = () => {
  return (
    <div id='landing-page-v3' className="full-screen-container">
      <Row className="full-screen-row">
        <Col xs={24} md={12} className="left-half">
          <div className="content-container">
            {/* <h1 className="left-heading">We build the Tech you need, just the way you want it.</h1> */}
            <AnimatedSentence />
            <p className="left-paragraph">
              We help entrepreneurs and business owners launch faster with a customized software solution—a full-featured minimum viable product (MVP)  or a no-code application tailored for unique needs.
            </p>
            {/* <p className="left-paragraph">
            We are a team of top software engineers, designers, marketing & business growth experts having worked with industry leading businesses in the world.
            </p>
            <p className="left-paragraph">
                We are on a mission to tech-enable small businesses with the industry leading best practices and optimisation techniques; ensuring they focus on what really matters: growing their business.
            </p> */}
            <Space>
              <Button style={{ marginTop: '40px', padding: '30px', fontSize: '30px' }} type="primary" size="large" target={'_blank'} href={FORM_LINK}>
                Let’s Talk
              </Button>
            </Space>
          </div>
        </Col>
        <Col xs={24} md={12} className="right-half">
          {/* <img src="https://i.imghippo.com/files/sAJI21727546274.jpg"></img> */}
          <img
            src="https://i.imghippo.com/files/sAJI21727546274.jpg"
            // src="https://i.imghippo.com/files/Etrzv1727546850.png"
            alt="Your Alt Text"
            className="landing-full-image"
          />
          {/* <StyledBulletPoints /> */}
          {/* <div className="content-container">
            <h2 className="right-heading">Key Features</h2>
            <ul className="styled-bullets">
              <li>High-performance architecture</li>
              <li>Responsive design for all devices</li>
              <li>Secure and scalable solutions</li>
              <li>Easy to use and customize</li>
              <li>24/7 support and maintenance</li>
            </ul>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default LandingPageV3;
