import React from 'react';
import { Typography } from 'antd';
import './StyledBulletPoints.css';

const { Title, Text } = Typography;

const StyledBulletPoints = () => {
  return (
    <div className="styled-container">
      <Title level={2} style={{color: '#4a4a4a'}} className="styled-heading">
        Core offerings
      </Title>
      <ul className="styled-list">
        <li>
          <Text className="styled-text">Secure, Scalable and Reliable Applications.</Text>
        </li>
        <li>
          <Text className="styled-text">Web, Mobile, Backend services.</Text>
        </li>
        <li>
          <Text className="styled-text">End-to-end solutions with Marketing and Sales.</Text>
        </li>
        <li>
          <Text className="styled-text">Next-level lead gen by Industry Maverick.</Text>
        </li>
        <li>
          <Text className="styled-text">AI-integrated applications.</Text>
        </li>
      </ul>
    </div>
  );
};

export default StyledBulletPoints;
