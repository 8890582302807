import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import './TestimonialsV3.css';
import { FORM_LINK } from './../constants';

const testimonialsData = [
    {
        description: 'Working with MaroonDev has been a game-changer for our business. They built a custom CRM that perfectly fits our needs and has streamlined our operations, saving us countless hours each week. The team is knowledgeable and professional. We recommend them for their awesome service.',
        name: 'Sarah Johnson',
        designation: 'Operations Manager',
        company: 'GreenTech Solutions',
    },
    {
        description: 'MaroonDev has been incredibly helpful and I would highly recommend them to anyone looking for workflow automation or similar needs. They are quick, responsive, friendly, and very communicative. I am looking forward to collaborating with them on future projects as well.',
        name: 'Mike Price',
        designation: 'Owner',
        company: 'Price Digital',
    },
    {
        description: 'MaroonDev’s solutions have significantly improved our efficiency and productivity. They developed an e-commerce platform for us that has boosted our online sales tremendously. The ability to customize and integrate various tools to fit our specific needs is impressive. We highly recommend MaroonDev for any e-commerce development needs.',
        name: 'Michael Lee',
        designation: 'IT Director',
        company: 'EcoBuild Enterprises',
    },
    {
        description: 'The team at MaroonDev created a web app for project management tasks. It is now efficient to track and manage landscape and blinds installation projects, ensuring timely delivery. They are a pleasure to work with and always deliver on time. We’re excited to continue our partnership with them.',
        name: 'Emma Rodriguez',
        designation: 'Marketing Manager',
        company: 'BrightWave Marketing',
    },
    {
        description: 'MaroonDev’s automation services have transformed the way we manage our projects. They developed a native CRM that integrates seamlessly with our existing systems, improving our project management efficiency. From the initial consultation to implementation, they provided exceptional support and guidance. I highly recommend MaroonDev to anyone seeking top-notch automation solutions.',
        name: 'John Peterson',
        designation: 'Project Manager',
        company: 'NexGen Innovations',
    },
];

const TestimonialsV3 = () => {
    // const handleGetInTouch = () => {
    //     window.location.href = '/contact'; // Adjust path to your routing
    // };

    return (
        <div id='testimonials-v3' className="testimonials-container">
            <h2 className="section-heading">Our clients' satisfaction is our top priority</h2>
            <p className="section-description">
                We value the feedback of our clients. <br />Here’s what they have to say about working with us.
            </p>

            <Row gutter={[16, 16]} justify="center">
                {testimonialsData.map((testimonial, index) => (
                    <Col xs={24} sm={12} md={8} key={index}>
                        <Card className="testimonial-card">
                            <p className="testimonial-description">"{testimonial.description}"</p>
                            <p className="testimonial-author">
                                <strong>{testimonial.name}</strong> - {testimonial.designation}, {testimonial.company}
                            </p>
                        </Card>
                    </Col>
                ))}
            </Row>

            <div className="get-in-touch-container">
                {/* <Button type="primary" className="get-in-touch-button" onClick={handleGetInTouch}>
                    Get in Touch
                </Button> */}
                <Button type="primary" className="get-in-touch-button" target={'_blank'} href={FORM_LINK}>
                    Get in Touch
                </Button>
            </div>
        </div>
    );
};

export default TestimonialsV3;
